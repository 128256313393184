import { setAuthToken } from "../../utils/setAuthToken";
import { loginApi, signUpApi } from "../api/user";
import { toast } from "react-toastify";
import {titleCase} from "../../helpers/textTransform";

import {
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
} from "./user.types.js";

import { setToken } from "../../utils/auth";

const startLogin = () => ({
  type: LOGIN_USER_START,
});
const successLogin = (payload) => ({
  type: LOGIN_USER_SUCCESS,
  payload,
});
const errorLogin = (payload) => ({
  type: LOGIN_USER_ERROR,
  payload,
});

export const loginUser = (data) => async (dispatch) => {
  try {
    dispatch(startLogin());
    const response = await loginApi(data);
    setToken(response.data.token);
    toast.success(response.data.message)
    window.location.replace("/");
    return dispatch(successLogin(response));
  } catch (e) {
    toast.error(titleCase(e.response.data.message));
    return dispatch(errorLogin(e.response));
  }
};

const signupRequest = (payload) => ({
  type: SIGNUP_REQUEST,
  payload,
});
const signupSuccess = (response) => ({
  type: SIGNUP_SUCCESS,
  payload: response,
});
const signupFailure = (payload) => ({
  type: SIGNUP_FAILURE,
  payload,
});

export const signUp = (data) => async (dispatch) => {
  try {
    dispatch(signupRequest());
    const response = await signUpApi(data);
    toast.success(response.data.message)
    return dispatch(signupSuccess(response));
  } catch (e) {
    toast.error(titleCase(e.response.data.message));
    return dispatch(signupFailure(e.response.data.message));
  }
};

export const logOut = () => {
  localStorage.clear();
  setAuthToken(false);
  window.location.href = "/";
};
