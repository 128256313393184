import {
  GET_ALL_COURSES_REQUEST,
  GET_ALL_COURSES_FAILURE,
  GET_ALL_COURSES_SUCCESS,
  GET_SINGLE_COURSE_REQUEST,
  GET_SINGLE_COURSE_FAILURE,
  GET_SINGLE_COURSE_SUCCESS,
  GET_COURSE_MODULES_REQUEST,
  GET_COURSE_MODULES_SUCCESS,
  GET_COURSE_MODULES_FAILURE,
  GET_ALL_MODULES_REQUEST,
  GET_ALL_MODULES_FAILURE,
  GET_ALL_MODULES_SUCCESS,
  GET_PURCHASED_COURSES_REQUEST,
  GET_PURCHASED_COURSES_SUCCESS,
  GET_PURCHASED_COURSES_FAILURE,
} from "./courses.types.js";

const initialState = {

  getAllCoursesLoading: false,
  getAllCoursesSuccess: false,
  allCourses: {},

  getSingleCourseLoading: false,
  getSingleCourseSuccess: false,
  singleCourse: {},

  getCourseModulesLoading: false,
  getCourseModulesSuccess: false,
  allCourseModules: [],

  getAllModulesLoading: false,
  getAllModulesSuccess: false,
  allModules: {},

  getPurchasedCoursesLoading: false,
  getPurchasedCoursesSuccess: false,
  purchasedCourses: {},

  errorMessage: "",
  error: false,

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COURSES_REQUEST:
      return {
        ...state,
        getAllCoursesLoading: true,
      };
    case GET_ALL_COURSES_SUCCESS:
      return {
        ...state,
        getAllCoursesLoading: false,
        getAllCoursesSuccess: true,
        allCourses: action.payload.data,
      };
    case GET_ALL_COURSES_FAILURE:
      return {
        ...state,
        getAllCoursesLoading: false,
        getAllCoursesSuccess: false,
        error: true,
      };

    case GET_SINGLE_COURSE_REQUEST:
      return {
        ...state,
        getSingleCourseLoading: true,
      };
    case GET_SINGLE_COURSE_SUCCESS:
      return {
        ...state,
        getSingleCourseLoading: false,
        getSingleCourseSuccess: true,
        singleCourse: action.payload.data,
      };
    case GET_SINGLE_COURSE_FAILURE:
      return {
        ...state,
        getSingleCourseLoading: false,
        getSingleCourseSuccess: false,
        error: true,
      };

    case GET_COURSE_MODULES_REQUEST:
      return {
        ...state,
        getCourseModulesLoading: true,
      };
    case GET_COURSE_MODULES_SUCCESS:
      return {
        ...state,
        getCourseModulesLoading: false,
        getCourseModulesSuccess: true,
        allCourseModules: action.payload.data,
      };
    case GET_COURSE_MODULES_FAILURE:
      return {
        ...state,
        getCourseModulesLoading: false,
        getCourseModulesSuccess: false,
        error: true,
      };

    case GET_ALL_MODULES_REQUEST:
      return {
        ...state,
        getAllModulesLoading: true,
      };
    case GET_ALL_MODULES_SUCCESS:
      return {
        ...state,
        getAllModulesLoading: false,
        getAllModulesSuccess: true,
        allModules: action.payload.data,
      };
    case GET_ALL_MODULES_FAILURE:
      return {
        ...state,
        getAllModulesLoading: false,
        getAllModulesSuccess: false,
        error: true,
      };

    case GET_PURCHASED_COURSES_REQUEST:
      return {
        ...state,
        getPurchasedCoursesLoading: true,
      };
    case GET_PURCHASED_COURSES_SUCCESS:
      return {
        ...state,
        getPurchasedCoursesLoading: false,
        getPurchasedCoursesSuccess: true,
        purchasedCourses: action.payload.data,
      };
    case GET_PURCHASED_COURSES_FAILURE:
      return {
        ...state,
        getPurchasedCoursesLoading: false,
        getPurchasedCoursesSuccess: false,
        error: true,
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
