import {
  LOGIN_USER_START,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  SIGNUP_REQUEST,
  SIGNUP_FAILURE,
  SIGNUP_SUCCESS,
} from "./user.types.js";

const initialState = {
  loginLoading: false,
  signUpLoading: false,
  data: {},

  loginSuccess: false,
  signUpSuccess: false,

  errorMessage: "",
  error: false,
  
  forgotPasswordSuccess: false,
  userDetails: {},

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_START:
      return {
        ...state,
        loginLoading: true,
      };
    case LOGIN_USER_SUCCESS:
      let { email, phoneNumber, login, firstname, lastname } =
        action.payload.data.data;
      let userObj = { email, phoneNumber, login, firstname, lastname };
      localStorage.setItem("userDetails", JSON.stringify(userObj));
      return {
        ...state,
        loginLoading: false,
        loginSuccess: true,
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loginLoading: false,
        loginSuccess: false,
        error: true,
      };

    case SIGNUP_REQUEST:
      return {
        ...state,
        signUpLoading: true,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        signUpLoading: false,
        signUpSuccess: true,
        data: action.payload,
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        signUpLoading: false,
        signUpSuccess: false,
        error: true,
      };
      case 'USER_INIT_STATE':
        return {
          ...state,
          loginSuccess: false,
          signUpSuccess: false,
          errorMessage: '',
          error: false,
        };

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
