/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
import styled from 'styled-components'
// import ComingSoon from "react-coming-soon";
import BgImg from "../../assets/images/coming soon image.jpeg"
import planningImg from "../../assets/images/planning.svg"

// Images
// import BgImg from "../../assets/images/headerbg.svg"
import Phone from "../../assets/images/phone.svg"
import Arrow from "../../assets/images/arrow.png"
import Mouse from "../../assets/images/mouse.svg"
import Users from "../../assets/images/users.svg"
import PlayStore from "../../assets/images/playstore.svg"
import AppStore from "../../assets/images/appstore.svg"

// React Icons
import { FaArrowRight, FaPlay } from "react-icons/fa"
import { Button } from '../../components/styled';

// Other components
import OurMission from './OurMission';
import OurCourses from './OurCourses';
import Studying from './Studying';
import Partners from './Partners';
import Navbar from '../../components/Navbar';
import BecomeAMember from './BecomeAMember';
import Mailing from './Mailing';
import Testimonials from './Testimonials';
import Footer from '../../components/Footer';

const HeaderBg = styled.section`
  min-height: calc(100vh - 107px);
  /* min-height: 100vh; */
  position: relative;
  /* display: flex; */
  align-items: center;
  &  .bg-header{  
    background: url(${BgImg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    margin-top: auto;
    padding-bottom: 4rem;
    
  }

  &::after{
    content: "";
    position: absolute;

  }
`;



export default function Homepage() {
  return (
    <>
    <div style={{  
  backgroundImage: "url(" + "https://react-coming-soon.maksv.me/default-image.jpeg" + ")",
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '100vh',
  display: 'flex',
  flexDirection: "column",
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
}}
>
  <div>
      <img style={{width: "40rem"}} src={planningImg} alt="bg img" />
  </div>

      <h3 style={{fontWeight: 'bold', fontSize: "3rem", textAlign: "center"}}>Coming Soon</h3>
    </div>
    </>
    
    // <ComingSoon
    //   image="https://react-coming-soon.maksv.me/default-image.jpeg"
    //   bgColor="#fff"
    //   textColor="#fff"
    //   illustration="planning"
    //   date = "2022-06-14"
    // />
    // <div>
    //   <HeaderBg className='flex flex-col lg:flex-row'>
    //     <div className='container h-full flex flex-col lg:flex-row items-center pt-20'
    //     >
    //       <div className='absolute z-20 w-full top-4 left-0'>
    //         <Navbar />
    //       </div>
    //       <div className='lg:w-5/12 grid gap-5 lg:gap-7 my-14 lg:my-6 text-center lg:text-left place-items-center lg:place-items-start'>
    //         <h2 className=' font-black text-secondary-color uppercase text-xl lg:text-3xl'>Learn &amp; Lead</h2>
    //         <h1 className='text-3xl lg:text-5xl font-extrabold text-primary-color'>Digital Learning Platform for Creative Industry!</h1>
    //         <p className="lg:font-semibold lg:text-lg text-text-color">Welcome to the Digital Learning Platform where Talents are trained to become Entrepreneurs &amp; Professionals in the Cultural &amp; Creative Industries.</p>
    //         <div className="flex flex-col md:flex-row gap-5">
    //           <img className="" src={PlayStore} alt="" />
    //           <img src={AppStore} alt="" />
    //         </div>
    //         <div className='flex flex-col md:flex-row items-center gap-5 relative'>
    //           <Button className='flex items-center'>Start Learning
    //             <FaArrowRight className='ml-2' />
    //           </Button>
    //           <div className='flex items-center gap-2'>
    //             <div className='bg-secondary-color rounded-full p-3'>
    //               <FaPlay className='text-2xl text-white' />
    //             </div>
    //             <p className='text-xl font-bold text-primary-color'>Watch Us</p>
    //           </div>
    //         <img className='absolute -left-20 top-3 hidden xl:block' src={Arrow} alt="" />
    //         </div>
    //         <div className='flex flex-col md:flex-row items-center gap-5 my-6 '>
    //           <img src={Users} alt="" />
    //           <p className='text-lg'>Over <b>1 million+ Students</b> using our
    //             Mobile app for <b>Learning new Skills.</b></p>
    //         </div>
    //         <img className='animate-bounce' src={Mouse} alt="" />
    //       </div>


    //       <img src={Phone} alt="" className='z-30 relative w-5/12 hidden lg:block' />
    //     </div>
    //     <img src={BgImg}
    //       className="lg:absolute right-0 top-0 h-full w-full lg:w-6/12 object-cover opacity-60" alt="" />
    //   </HeaderBg>

    //   <Studying />
    //   <OurCourses />
    //   <OurMission />
    //   <Testimonials />
    //   <Mailing />
    //   <Partners />
    //   <BecomeAMember />
    //   <Footer />
    // </div>

  )
}
