// export const GET_CART_ITEMS_REQUEST = "GET_CART_ITEMS_REQUEST";
// export const GET_CART_ITEMS_SUCCESS = "GET_CART_ITEMS_SUCCESS";
// export const GET_CART_ITEMS_FAILURE = "GET_CART_ITEMS_FAILURE";

// export const ADD_CART_ITEMS_START = "ADD_CART_ITEMS_START";
// export const ADD_CART_ITEMS_SUCCESS = "ADD_CART_ITEMS_SUCCESS";
// export const ADD_CART_ITEMS_FAILURE = "ADD_CART_ITEMS_FAILURE";

// export const REMOVE_CART_ITEMS_START = "REMOVE_CART_ITEMS_START";
// export const REMOVE_CART_ITEMS_SUCCESS = "REMOVE_CART_ITEMS_SUCCESS";
// export const REMOVE_CART_ITEMS_FAILURE = "REMOVE_CART_ITEMS_FAILURE";

export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";

export const BUY_SINGLE_COURSE_REQUEST = "BUY_SINGLE_COURSE_REQUEST";
export const BUY_SINGLE_COURSE_SUCCESS = "BUY_SINGLE_COURSE_SUCCESS";
export const BUY_SINGLE_COURSE_FAILURE = "BUY_SINGLE_COURSE_FAILURE";

export const BUY_SINGLE_MODULE_REQUEST = "BUY_SINGLE_MODULE_REQUEST";
export const BUY_SINGLE_MODULE_SUCCESS = "BUY_SINGLE_MODULE_SUCCESS";
export const BUY_SINGLE_MODULE_FAILURE = "BUY_SINGLE_MODULE_FAILURE";

// export const CLEAR_CART_REQUEST = "CLEAR_CART_REQUEST";
// export const CLEAR_CART_SUCCESS = "CLEAR_CART_SUCCESS";
// export const CLEAR_CART_FAILURE = "CLEAR_CART_FAILURE";
