import { combineReducers } from "redux";
import user from "./User/user.reducer";
import courses from "./Courses/courses.reducer";
import cart from "./Cart/cart.reducer";

const rootReducer = combineReducers({
  user,
  courses,
  cart,
});

export default rootReducer;
