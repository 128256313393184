import {
  // GET_CART_ITEMS_REQUEST,
  // GET_CART_ITEMS_FAILURE,
  // GET_CART_ITEMS_SUCCESS,
  // ADD_CART_ITEMS_START,
  // ADD_CART_ITEMS_SUCCESS,
  // ADD_CART_ITEMS_FAILURE,
  // REMOVE_CART_ITEMS_START,
  // REMOVE_CART_ITEMS_SUCCESS,
  // REMOVE_CART_ITEMS_FAILURE,
  CHECKOUT_REQUEST,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAILURE,
  BUY_SINGLE_COURSE_REQUEST,
  BUY_SINGLE_COURSE_SUCCESS,
  BUY_SINGLE_COURSE_FAILURE,
  BUY_SINGLE_MODULE_REQUEST,
  BUY_SINGLE_MODULE_SUCCESS,
  BUY_SINGLE_MODULE_FAILURE,
  // CLEAR_CART_REQUEST,
  // CLEAR_CART_SUCCESS,
  // CLEAR_CART_FAILURE,
} from "./cart.types.js";

const initialState = {
  getCartItemsLoading: false,
  getCartItemsSuccess: false,
  cartItems: {},

  addCartItemsLoading: false,
  addCartItemsSuccess: false,

  removeCartItemsLoading: false,
  removeCartItemsSuccess: false,

  checkOutLoading: false,
  checkOutSuccess: false,
  checkOutData: [],

  buySingleCourseLoading: false,
  buySingleCourseSuccess: false,
  buySingleCourseData: [],

  buySingleModuleLoading: false,
  buySingleModuleSuccess: false,
  buySingleModuleData: [],

  clearCartLoading: false,
  clearCartSuccess: false,
  clearCartData: [],

  errorMessage: "",
  error: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // case GET_CART_ITEMS_REQUEST:
    //   return {
    //     ...state,
    //     getCartItemsLoading: true,
    //   };
    // case GET_CART_ITEMS_SUCCESS:
    //   return {
    //     ...state,
    //     getCartItemsLoading: false,
    //     getCartItemsSuccess: true,
    //     cartItems: action.payload.data,
    //   };
    // case GET_CART_ITEMS_FAILURE:
    //   return {
    //     ...state,
    //     getCartItemsLoading: false,
    //     getCartItemsSuccess: false,
    //     error: true,
    //   };

    // case ADD_CART_ITEMS_START:
    //   return {
    //     ...state,
    //     addCartItemsLoading: true,
    //   };
    // case ADD_CART_ITEMS_SUCCESS:
    //   return {
    //     ...state,
    //     addCartItemsLoading: false,
    //     addCartItemsSuccess: true,
    //     cartItems: action.payload,
    //   };
    // case ADD_CART_ITEMS_FAILURE:
    //   return {
    //     ...state,
    //     addCartItemsLoading: false,
    //     addCartItemsSuccess: false,
    //     error: true,
    //   };

    // case REMOVE_CART_ITEMS_START:
    //   return {
    //     ...state,
    //     removeCartItemsLoading: true,
    //   };
    // case REMOVE_CART_ITEMS_SUCCESS:
    //   return {
    //     ...state,
    //     removeCartItemsLoading: false,
    //     removeCartItemsSuccess: true,
    //     cartItems: action.payload.data,
    //   };
    // case REMOVE_CART_ITEMS_FAILURE:
    //   return {
    //     ...state,
    //     removeCartItemsLoading: false,
    //     removeCartItemsSuccess: false,
    //     error: true,
    //   };

    case CHECKOUT_REQUEST:
      return {
        ...state,
        checkOutLoading: true,
      };
    case CHECKOUT_SUCCESS:
      return {
        ...state,
        checkOutLoading: false,
        checkOutSuccess: true,
        checkOutData: action.payload.data,
        errorMessage: "",
        error: false,
      };
    case CHECKOUT_FAILURE:
      return {
        ...state,
        checkOutLoading: false,
        checkOutSuccess: false,
        error: true,
        errorMessage: action.payload,
      };

    case BUY_SINGLE_COURSE_REQUEST:
      return {
        ...state,
        buySingleCourseLoading: true,
      };
    case BUY_SINGLE_COURSE_SUCCESS:
      return {
        ...state,
        buySingleCourseLoading: false,
        buySingleCourseSuccess: true,
        buySingleCourseData: action.payload.data,
      };
    case BUY_SINGLE_COURSE_FAILURE:
      return {
        ...state,
        buySingleCourseLoading: false,
        buySingleCourseSuccess: false,
        error: true,
      };

    case BUY_SINGLE_MODULE_REQUEST:
      return {
        ...state,
        buySingleModuleLoading: true,
      };
    case BUY_SINGLE_MODULE_SUCCESS:
      return {
        ...state,
        buySingleModuleLoading: false,
        buySingleModuleSuccess: true,
        buySingleModuleData: action.payload.data,
      };
    case BUY_SINGLE_MODULE_FAILURE:
      return {
        ...state,
        buySingleModuleLoading: false,
        buySingleModuleSuccess: false,
        error: true,
      };

    // case CLEAR_CART_REQUEST:
    //   return {
    //     ...state,
    //     clearCartLoading: true,
    //   };
    // case CLEAR_CART_SUCCESS:
    //   return {
    //     ...state,
    //     clearCartLoading: false,
    //     clearCartSuccess: true,
    //     clearCartData: action.payload.data,
    //   };
    // case CLEAR_CART_FAILURE:
    //   return {
    //     ...state,
    //     clearCartLoading: false,
    //     clearCartSuccess: false,
    //     error: true,
    //   };

    default:
      return {
        ...state,
      };
  }
};

export default reducer;





