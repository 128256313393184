import { Route, Switch } from 'react-router-dom';
// import Footer from '../components/Footer';
// import Navbar from '../components/Navbar';
// import About from '../pages/about';
// import Login from '../pages/auth/login';
import Register from '../pages/auth/register';
// import ForgotPassword from '../pages/auth/forgotPassword';
// import Contact from '../pages/contact';
// import Courses from '../pages/courses';
// import FAQ from '../pages/faq';
import Homepage from '../pages/homepage';

export default function Routes() {

  return (
    <div>
      {/* <Navbar /> */}
      <Switch>
        <Route exact path="/" component={Homepage} />
        {/* <Route exact path="/about" component={About} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/faq" component={FAQ} />
        <Route exact path="/courses" component={Courses} /> */}
        {/* <Route exact path="/login" component={Login} /> */}
        <Route exact path="/register" component={Register} />
        {/* <Route exact path="/forgot-password" component={ForgotPassword} /> */}
      </Switch>
      {/* <Footer /> */}
    </div>
  )
}

