import React, { useState } from "react";
import { FormGroup } from "../Styles";
import { Icon } from '@iconify/react';
import ReactTooltip from 'react-tooltip';
import {Link} from "react-router-dom"

const Input = ({
  label,
  placeholder,
  required,
  type = "text",
  onChange,
  onBlur,
  error,
  icon,
  toolTipIcon,
  toolTipText,
  marginbottom,
  forgotPasswordLink,
  forgotPassword,
  ...props
}) => {
  const [inputType, setInputType] = useState(type);
  const handleVisibility = () => {
    if (inputType === "password") {
      return setInputType("text");
    }
    return setInputType("password");
  };

  return (
    <FormGroup marginbottom={marginbottom} required={required}>

       <ReactTooltip place="top" type="info" multiline="true" effect="float" delayHide={100} id={props.name} />
      
      <div className="flexRow-space-between">
      <label>{label}</label>

<div style={{display: "flex", gap: "0.5rem"}}>
  {forgotPassword && <small><Link style={{color: "#65BFBB"}} to={forgotPasswordLink}>Forgot Password?</Link></small>  }

  {toolTipIcon && <div data-for={props.name} data-tip={toolTipText}><Icon  icon="ant-design:question-circle-filled" color="#341d4b" /></div>}
</div>
   
     {/* {toolTipIcon && <Icon data-for={props.name} data-tip={toolTipText}  icon="ant-design:question-circle-filled" color="#341d4b" />} */}
      
      </div>

      <div className="input-container">
        {icon && <img src={icon} alt="search icon" />}
        <input
          type={inputType}
          placeholder={placeholder}
          required={required}
          onChange={onChange}
          onBlur={onBlur}
          {...props}
          autoComplete="new-password"
        />
        {type === "password" && (
          <i
            className="eye-icon fas fa-eye-slash"
            onClick={handleVisibility}
          ><Icon icon="bi:eye-slash" /></i>
          
        )}
      </div>
      {error ? <p className="error">{error}</p> : ""}
    </FormGroup>
  );
};

export default Input;
