import axios from "../../config/axios";

export const loginApi = async (data) => {
  const request = await axios.post("learner/login/", data);
  return request;
};

export const signUpApi = async (data) => {
  const request = await axios.post("learner/create/", data);
  return request;
};

// export const forgotPasswordApi = async (data) => {
//   const request = await axios.post('/users/reset-password/', { email: data });
//   return request;
// };

// export const updatePasswordApi =  (token, password) => {
//   return  ''
// };

