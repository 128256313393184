import styled from "styled-components";


export const Button = styled.button`
  background: #341D4B;
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  outline: none;
  border: none;
  padding: 1rem 1.75rem;
  border-radius: 2rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  display: flex;
  align-items: center;
  justify-content: center;
`;


export const TickDiv = styled.div`
  display: flex;
  align-items: center;
  color: #341D4B;
  font-weight: 600;

  div{
    background-color: #F2EBF9;
    border-radius: 4px;
    margin-right: 0.35rem;
    height: 16px;
    width: 16px;

    svg{
      color: #341D4B;
    }
  }
`;

export const FooterDiv = styled.footer`


  ul{
    li{
      line-height: 2.75;
      color: #92869D;

      &:first-of-type{
        font-weight: 700;
        font-size: 1.25rem;;
        margin-bottom: .5rem;
        color: white;
      }
    }
  }

  .socials{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0;
    a{
      display: grid;
      place-items: center;
      background: #44305a;
      border-radius: 2000px;
      width: 42px;
      height: 42px;
      margin-left: 1rem;
      svg{
        fill: white;
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const FormControl = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  margin-bottom: 1.5rem;


  input,select,textarea{
    border: 1px solid rgba(18, 17, 39, 0.12);
    border-radius: 8px;
    padding: 0.75rem;
  }
  label{
    color: #341D4B;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 0.5rem;
  }
`;