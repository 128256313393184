import * as Yup from 'yup';
import moment from 'moment';

const selectOptions = [        
  {label:"Wazobia Pro", value:"Wazobia Pro"},
  {label:"Entrepreneurship In Music", value:"Entrepreneurship In Music"},
  {label:"Series Writing", value:"Series Writing"},
  {label:"Becoming an OAP (On Air Personality)", value:"Becoming an OAP (On Air Personality)"},
  {label:"Entrepreneurship In The Fashion Industry", value:"Entrepreneurship In The Fashion Industry"},
];

export const signInValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required("Email Address is required"),
  password: Yup.string()
    .required("Password is required")
    .max(50, 'Must be 50 characters or less')
    .min(6, 'Must be above 6 characters'),
});

export const signUpValidationSchema = Yup.object().shape({
  firstname: Yup.string()
    .matches('[a-zA-Z]+', {
      message: 'Firstname should be at least 2 characters.',
      excludeEmptyString: false,
    })
    .min(2, 'First Name should be at least 2 characters.')
    .max(225, 'Too long. It should be 225 characters long.')
    .required('First Name is required'),

  lastname: Yup.string()
    .min(2, 'Last Name should be at least 2 characters.')
    .max(225, 'Too long. It should be 255 characters long.')
    .matches('[a-zA-Z]+', {
      message: 'Lastname must be alphabets.',
      excludeEmptyString: false,
    })
    .required('Last Name is required'),

       login: Yup.string()
    .min(2, 'Username should be at least 2 characters.')
    .max(20, 'Too long.')
    .matches(/^[a-zA-Z0-9]+$/, {
      message: 'Username must start with an alphabet',
    })
    .required('Username is required'),

    password: Yup.string()
    .required('Password is required')
    .min(8, 'Must Contain at least 8 Characters'),

    phoneNumber: Yup.string()
    .required("Phone Number is Required")
    .matches(
      /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
      "Phone number is not valid"
    ),

  email: Yup.string()
    .email('Invalid email address')
    .min(5, 'Must be above 5 characters!')
    .max(225, 'Too Long!')
    .required('Email is required'),

    acceptTerms: Yup.bool()
      .oneOf([true], 'Accept Terms & Conditions Below')
});

export const contactUsValidationSchema = Yup.object().shape({
  firstname: Yup.string()
    .matches('[a-zA-Z]+', {
      message: 'Firstname must be at least 2 characters long.',
      excludeEmptyString: false,
    })
    .min(2, 'First Name should be at least 2 characters.')
    .max(225, 'Too long. First Name should be 225 characters long.')
    .required('First Name is required'),

    subject: Yup.string()
    .matches('[a-zA-Z]+', {
      message: 'Subject must be at least 2 characters long.',
      excludeEmptyString: false,
    })
    .min(2, 'Subject should be at least 2 characters.')
    .max(225, 'Too long. Subject should be 225 characters long.')
    .required('Subject is required'),

    message: Yup.string()
    .required('Message is required'),

    trainings: Yup.string()
    .oneOf(selectOptions.map(element=>element.label))
    .required('Training is required'),

  lastname: Yup.string()
    .min(2, 'Last Name should be at least 2 characters long.')
    .max(225, 'Too long. Last Name should be 255 characters long.')
    .matches('[a-zA-Z]+', {
      message: 'Lastname must be alphabets.',
      excludeEmptyString: false,
    })
    .required('Last Name is required'),

  email: Yup.string()
    .email('Invalid email address')
    .min(5, 'Must be above 5 characters!')
    .max(225, 'Too Long!')
    .required('Email is required'),

  phoneNumber: Yup.string()
  .required("Phone Number is Required")
  .matches(
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    "Phone number is not valid"
  ),
});

export const updateProfileValidationSchema = {
  firstname: Yup.string()
    .required('First Name is required')
    .max(100, 'Must be 100 characters or less'),
  lastname: Yup.string()
    .required('Last Name is required')
    .max(100, 'Must be 100 characters or less'),
};

export const forgotValidationSchema = {
  email: Yup.string().email('Invalid email address').required(),
};

export const VerifySchema = {
  email: Yup.string().email('Enter a valid email address').required('Required'),
};

export const resetPasswordValidationSchema = {
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters long')
    .max(50, 'Password must be at least 6 characters long')
    .required(),

  password2: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'password must match',
  ),
};

export const formatDate = (date) => {
  return moment(date, 'hh:mm A').format('"hh:mm A"');
};

// eslint-disable-next-line no-unused-vars
const getAdayBeforeToday = () => {
  let today = new Date();
  today.setDate(today.getDate() - 1);
  return today;
};

export const changePassword = Yup.object({
  current_password: Yup.string()
    .required('The Current Password is required')
    .min(8, 'Must be at least 8 Characters'),
  new_password: Yup.string()
    .oneOf([Yup.ref('current_password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

