import React from 'react'
import { Button, TickDiv } from '../../components/styled'
import { TiTick } from "react-icons/ti"
import { FaPlay, FaCartPlus } from "react-icons/fa"
import { FiArrowRight } from "react-icons/fi"
import logo from "../../assets/images/logo-without-text.svg"
import styled from "styled-components"
import CourseImg from "../../assets/images/course1.svg";
import CoursesLogo from "../../assets/images/courses-logo.svg";

const coursesFeatures = [
  "The Use of Camera",
  "Sound and Lighting in Production",
  "Postproduction in Premiere Pro",
  "Finalizing Video Projects"
]

export const TitleWithCircle = styled.div`
    /* margin: auto; */
    /* text-align: center; */
    text-transform: capitalize;
h3{

  font-weight: 700;
  font-size: 36px;
  line-height: 160%;
  color: #341D4B;
  position: relative;

    display: inline-block;
    z-index: 10;
  
  span{
    color: #65BFBB;
  }
  
  &::after{
    content: ""; 
    position: absolute;
    width: 63px;
    height: 63px;
    left: -20px;
    top: -7px;
    border-radius: 50%;
    z-index: -1;
    background-color: #65BFBB;
  }
}
  `;

const DivWithSqrs = styled.div`
  position: relative;

  .overlay{
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 25px;
    cursor: pointer;
    display: grid;
    place-items: center;
    transition: ease-in-out 1000ms;
    div{
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      position: absolute;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      /* background: linear-gradient(149.55deg, #FFC700 -7.32%, #FF782D 121.76%); */
      background-color: #65BFBB;
      color: white !important;
      display: grid;
    place-items: center;
    }
    
    &:hover{
      background-color: rgba(0, 0, 0, 0.5);

    }
  }

  &::after,&::before{
    content: ""; 
    position: absolute;
    z-index: -1;
    border-radius: 30px;
  }

  &:after{
    width: 138px;
    height: 138px;
    top: -20px;
    left: -20px;
    background: #341D4B;
    z-index: -1;
  }
  
  &::before{
    width: 231px;
    height: 231px;
    bottom: -20px;
    right: -20px;
    background: #65BFBB;
    
  }
`;

export default function OurCourses() {
  return (
    <div className='container pt-20 pb-48'>
      <div className='z-10 relative'>

        <div className='grid place-items-center gap-4 text-center mb-10'>
          <img src={logo} alt="" />
          <h2 className='text-primary-color text-4xl font-bold'>Our Courses</h2>
          <p className='font-medium leading-7 text-text-color mx-auto lg:w-11/12'>Our training courses cover basic and advanced trainings in the media and creative industries, equipping learners with the right skills in broadcasting, film-making, entrepreneurship in music, cultural and creative industries. These include, but not limited to, Radio Hosting, Writing For TV Series, Video Shooting and Editing, Music business, Fashion Business, Makeup and lots more. Learn more</p>
        </div>

        <section className='grid place-items-center gap-x-20 gap-y-10 grid-cols-1 lg:grid-cols-2 my-32'>
          <div className='gap-8 flex flex-col text-center lg:text-left items-center lg:items-start pb-12 lg:pb-0'>

            <TitleWithCircle>
              <h3>
                Shooting &amp; <span>Editing Videos</span>
              </h3>
            </TitleWithCircle>

            <p className='text-text-color font-semibold leading-9 text-lg'>In this course, participants will learn the basics of video storytelling and how to shoot and edit video materials professionally, from planning your shooting and preparing your gear, framing shots, composing sequences, sound, and lighting properly.</p>

            <div className='flex flex-wrap justify-center lg:justify-start gap-x-5 gap-y-3'>
              {
                coursesFeatures.map(feature => {
                  return (
                    <TickDiv key={feature}>
                      <div>
                        <TiTick />
                      </div>
                      <label htmlFor="">{feature}</label>
                    </TickDiv>
                  )
                })
              }

            </div>

            <div className='flex flex-col md:flex-row items-center gap-6'>

              <Button className='flex items-center'>
                <FaCartPlus className='text-secondary-color text-3xl inline mr-3' />
                Buy Course</Button>
              <div className='flex items-center'>
                <p className='text-text-color font-bold text-lg'>Discover More</p>
                <FiArrowRight className="text-xl ml-3" />
              </div>
            </div>
            <p className='font-semibold text-lg text-text-color'><span className='text-secondary-color'>*</span> Get access to All Our Courses for a One Time Fee.</p>
          </div>

          <DivWithSqrs>
            <div className='overlay'>
              <div>
                <FaPlay className='text-4xl' />
              </div>
            </div>
            <img src={CourseImg} alt="" />
            <img className='w-16 absolute right-3 bottom-3' src={CoursesLogo} alt="" />
          </DivWithSqrs>
        </section>

        <Button className='mx-auto !bg-white !text-primary-color shadow-lg'>Show All Courses</Button>
      </div>

    </div>

  )
}
