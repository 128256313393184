export const GET_ALL_COURSES_REQUEST = "GET_ALL_COURSES_REQUEST";
export const GET_ALL_COURSES_SUCCESS = "GET_ALL_COURSES_SUCCESS";
export const GET_ALL_COURSES_FAILURE = "GET_ALL_COURSES_FAILURE";

export const GET_SINGLE_COURSE_REQUEST = "GET_SINGLE_COURSE_REQUEST";
export const GET_SINGLE_COURSE_SUCCESS = "GET_SINGLE_COURSE_SUCCESS";
export const GET_SINGLE_COURSE_FAILURE = "GET_SINGLE_COURSE_FAILURE";

export const GET_COURSE_MODULES_REQUEST = "GET_COURSE_MODULES_REQUEST";
export const GET_COURSE_MODULES_SUCCESS = "GET_COURSE_MODULES_SUCCESS";
export const GET_COURSE_MODULES_FAILURE = "GET_COURSE_MODULES_FAILURE";

export const GET_ALL_MODULES_REQUEST = "GET_ALL_MODULES_REQUEST";
export const GET_ALL_MODULES_SUCCESS = "GET_ALL_MODULES_SUCCESS";
export const GET_ALL_MODULES_FAILURE = "GET_ALL_MODULES_FAILURE";

export const GET_PURCHASED_COURSES_REQUEST = "GET_PURCHASED_COURSES_REQUEST";
export const GET_PURCHASED_COURSES_SUCCESS = "GET_PURCHASED_COURSES_SUCCESS";
export const GET_PURCHASED_COURSES_FAILURE = "GET_PURCHASED_COURSES_FAILURE";
