import styled from "styled-components";
import mission from "../../assets/images/mission.svg"
// import Curve from "../../assets/images/bottomCurve.svg"
import { Button } from "../../components/styled"
import { FaPlay } from "react-icons/fa"

const BgCurve = styled.section`
/* @media (min-width: 1024px) { 
  clip-path: polygon(0 0, 100% 0, 100% 79%, 50% 100%, 0 79%);
}	 */
.custom-shape-divider-bottom-1651646908 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1651646908 svg {
    position: relative;
    display: block;
    width: 100%;
    height: 300px;
    margin: auto;
}

.custom-shape-divider-bottom-1651646908 .shape-fill {
    fill: #FFFFFF;
}
`;

export default function Studying() {
  return (
    <BgCurve className='bg-primary-color relative' >
      <div className='container grid place-items-center grid-cols-1 lg:grid-cols-2 '>
        <div className="gap-10 grid place-items-center lg:place-items-start text-center lg:text-left pt-10">
          <h1 className="text-secondary-color text-5xl font-bold"><span className="text-white">Studying</span> Online is now much Easier! </h1>
          <p className="text-white font-semibold">Our Mission is to raise creative entrepreneurs with exceptional talents who would
            redefine the creative industry in Africa through best practices in filmmaking,
            Media services, audiovisual production, and content creation.</p>

          <div className='flex items-center gap-5'>
            <Button className="!bg-white !text-primary-color">Join Now</Button>
            <div className='flex items-center gap-2'>
              <div className='bg-white rounded-full p-4'>
                <FaPlay className='text-xl text-secondary-color' />
              </div>
              <p className='text-xl font-bold text-secondary-color'>Watch how we Teach?</p>
            </div>
          </div>

          {/* Features */}
          <div className="max-w-lg">
            <div className="bg-secondary-color p-6 lg:pb-8 rounded-md">
              <h4 className="text-white font-bold lg:font-extrabold text-xl lg:text-3xl">Innocative, Accessible &amp; Professional</h4>
              <p className="text-white py-6">Wazobia Academy is an innovative, accessible, and professional online training institution for individuals with keen interest in audiovisuals, cultural, and media professions.</p>
              {/* <button className="text-primary-color bg-transparent flex items-center border-0 outline-none">View All Features

              </button> */}
            </div>
          </div>
          <div class="custom-shape-divider-bottom-1651646908 ">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" class="shape-fill"></path>
    </svg>
</div>
        </div>

        <div>
          <img src={mission} alt="" />
        </div>
      </div>
      {/* <div className="bigCircle fromTop"></div> */}
    </BgCurve>
  )
}
