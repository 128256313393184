import Select from "../../../components/Select/Select"
import Input from "../../../components/Input";
import cx from "classnames";
import styles from "./register.module.scss"

import "../auth-slider.scss"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import sliderImage1 from "../../../assets/images/registerImg1.png";
import sliderImage2 from "../../../assets/images/registerImg2.png";
import sliderImage3 from "../../../assets/images/registerImg3.png";
import playStoreBtn from "../../../assets/images/playstore.png";
import appStoreBtn from "../../../assets/images/appstore.png";

import { signUp } from "../../../redux/User/user.action";
import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated } from "../../../utils/auth";
// import PageContainer from "../../../components/PageContainer/PageContainer";
import { Link } from "react-router-dom";

import { useForm, Controller } from "react-hook-form";
import { signUpValidationSchema } from "../../../helpers/validation";
import { yupResolver } from "@hookform/resolvers/yup";

import { FaArrowRight } from "react-icons/fa"
// import heroImage from "../../../assets/images/loginHeroImage.png"

// import Navbar from '../../../components/Navbar';
// import Footer from '../../../components/Footer';

const Register = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);

  const { signUpLoading } = user;

  const resolver = yupResolver(signUpValidationSchema);

  const defaultValues = {
    email: "",
    firstname: "",
    phoneNumber: "",
    lastname: "",
    password: "",
    login: "",
    acceptTerms: false,
  };

  const ageRange = [
    { value: "18-25", label: "18-25" },
    { value: "26-35", label: "26-35" },
    { value: "36-45", label: "36-45" },
    { value: "46-55", label: "46-55" },
    { value: "56+", label: "56 and above" },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({ defaultValues, resolver, mode: "all" });

  const checkIsAuthenticated = isAuthenticated();

  const createUser = async (data) => {
    let { acceptTerms, age, ...rest } = data;
    let newData = {...rest}
    const response = await dispatch(signUp(newData));
    if (response.payload.status === 201) {
      reset();
    } else {
      dispatch({
        type: "USER_INIT_STATE",
      });
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    cssEase: "linear",
    className: "auth-slider",
    arrows: false
    };

  const imgAndPath = [
    { img: sliderImage1, link: "#" },
    { img: sliderImage2, link: "#" },
    { img: sliderImage3, link: "#" }
  ]

  return (
    <>
      {checkIsAuthenticated ? (
        <div
          style={{ height: "50vh", padding: "0rem 1rem", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
        >
          <p style={{ textAlign: "center" }}>You are logged in already</p>
          <div
            style={{ marginTop: "1rem", justifyContent: "center", gap: "1rem" }}
            className="flex-row"
          >
            <Link className=" bg-primary-color text-white px-5 py-2 mt-4 rounded-full" to={"/"}>
              Go To The Homepage
            </Link>
          </div>
        </div>
      ) : (
<>
{/* <div className='absolute z-20 w-full top-0 left-0'>
      <Navbar />
    </div> */}

        <div className={cx(styles.registerPageWrapper)}>
          <section className={cx(styles.formWrapper)}>

            <div className={cx(styles.container)}>
              <h3>LEARN &amp; LEAD</h3>
              <h1>Create An Account</h1>
              <p className={cx(styles.caption)}>Welcome to the Digital Learning Platform where Talents are trained to become Enterpreneurs & Professionals in the Cultural and Creative Industries.</p>

              <form
                onSubmit={handleSubmit((data) => createUser(data))}
                className={cx(styles["registration-form"])}
              >
                <div className={cx(styles["input-wrapper"])}>

                <div className={cx(styles.formGroup, styles.maxWidth)}>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={"example@example.com"}
                          type="email"
                          label="Email Address"
                          required={true}
                          toolTipIcon
                          toolTipText={"Enter a valid email address"}
                          error={errors?.email && errors?.email?.message}
                        />
                      )}
                    />
                  </div>

                  <div className={cx(styles.formGroup)}>
                    <Controller
                      name="firstname"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={"First Name"}
                          type="text"
                          label="First Name"
                          required={true}
                          error={
                            errors?.firstname && errors?.firstname?.message
                          }
                        />
                      )}
                    />
                  </div>

                  <div className={cx(styles.formGroup)}>
                    <Controller
                      name="lastname"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={"Last Name"}
                          type="text"
                          label="Last Name"
                          required={true}
                          error={errors?.lastname && errors?.lastname?.message}
                        />
                      )}
                    />
                  </div>

             

                  <div className={cx(styles.formGroup)}>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={"Password"}
                          type="password"
                          label="Password"
                          required={true}
                          toolTipIcon
                          toolTipText={`The password should be at least eight characters long.<br> To make it stronger, use upper and lower case letters,<br> numbers, and symbols like ! " ? $ % ^ &`}
                          error={errors?.password && errors?.password?.message}
                        />
                      )}
                    />
                  </div>

                  <div className={cx(styles.formGroup)}>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={"+234 8123 456 789"}
                          type="number"
                          label="Phone Number"
                          required={true}
                          error={
                            errors?.phoneNumber && errors?.phoneNumber?.message
                          }
                        />
                      )}
                    />
                  </div>

                  <div className={cx(styles.formGroup)}>
                    <Controller
                      name="login"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={"Username"}
                          type="text"
                          label="Username"
                          required={true}
                          error={errors?.login && errors?.login?.message}
                        />
                      )}
                    />
                  </div>

                  
                  <div className={cx(styles.formGroup)}>
                    <Controller
                      name="age"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder={"Age"}
                          type="text"
                          label="Age Range"
                          required={true}
                          error={errors?.age && errors?.age?.message}
                          options={ageRange}
                        />
                      )}
                    />
                  </div>

              
                </div>

                <div>
                  {errors?.acceptTerms?.message && (
                    <small style={{ color: "red" }}>
                      {errors?.acceptTerms?.message}
                    </small>
                  )}
                  <div
                    className={cx(styles.termsAndConditions)}
                  >
                    <input
                      style={{ width: "auto" }}
                      id="consent"
                      name="acceptTerms"
                      type="checkbox"
                      {...register("acceptTerms")}
                    />
                    <label htmlFor="consent">
                      I hereby certify that the information provided is mine and
                      I have gone through the{" "}
                      <Link
                        style={{ color: "#65BFBB" }}
                        to="/terms-and-condition"
                      >
                        Terms and Conditions.
                      </Link>
                    </label>
                  </div>
                </div>

                <div
                  className={cx(styles.footer)}
                  style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: "1rem" }}
                >

                  <button onClick={handleSubmit((data) => createUser(data))}
                    disabled={signUpLoading ? true : false} className={cx(styles.loginBtn)}>{signUpLoading ? "Signing Up..." : "Create Account"}
                    <FaArrowRight className='ml-2' />
                  </button>

                  {/* <p>Already a Member? <Link to="/login"> Sign In </Link></p> */}
                </div>
              </form>

            </div>
          </section>

          <section className={cx(styles.imageDiv)}>
            {/* <img className={cx(styles.imageDivBg)} src={heroImage} alt="heroImage" /> */}

            <div className={cx(styles.carouselWrapper)}>
              <Slider {...settings}>
                {
                  imgAndPath.map(partner => {
                    return (

                      <img className='carousel-image' src={partner.img} alt="" />
                    )
                  })
                }
              </Slider>

              <div className={cx(styles.btnGroup)}>
                <a href="https://" className={cx(styles.btn)}><img src={playStoreBtn} alt="" /></a>
                <a href="https://" className={cx(styles.btn)}><img src={appStoreBtn} alt="" /></a>
              </div>

            </div>


          </section>

        </div>
        {/* <Footer /> */}

        </>
      )}
    </>);
};

export default Register;
